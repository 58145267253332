<template>
  <div class="nice-pagination">
    <strong v-if="showProgress">
      {{ per * (page - 1) + (pageSize ? 1 : 0) }} – {{ per * (page - 1) + pageSize }} {{ $t("psPagination.of") }}
      {{ $customFilters.prettyNumber(total) }}
    </strong>

    <el-pagination
      background
      :layout="layoutWithFallback"
      v-model:current-page="page"
      v-model:page-size="per"
      @current-change="emit('current-change', $event)"
      @size-change="emit('size-change', $event)"
      @update:per="emit('update:per', $event)"
      @update:page="emit('update:page', $event)"
      :page-sizes="pageSizes"
      :total="total"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
const props = withDefaults(
  defineProps<{
    showProgress?: boolean
    pageSize?: number
    total: number
    pageSizes?: number[]
    layout?: string
  }>(),
  {
    showProgress: true,
    pageSize: 20,
  }
)

const emit = defineEmits(["update:per", "update:page", "current-change", "size-change"])

const per = defineModel("per", {
  type: Number,
  default: 10,
})

const page = defineModel("page", {
  type: Number,
  default: 1,
})

const layoutWithFallback = computed(
  () => props.layout || (props.pageSizes ? "sizes, prev, pager, next" : "prev, pager, next")
)
</script>

<style lang="postcss" scoped>
.nice-pagination {
  @apply flex justify-between items-center py-2 px-[30px];
}
</style>
